<template>
  <div>
    <q-form ref="editForm">
      <div class="row">
        <div class="col-md-12 col-lg-12">
          <c-card title="설비가동 정보" class="cardClassDetailForm" topClass="topcolor-blue">
            <template slot="card-detail">
              <div class="col-4">
                <c-datepicker
                  :disabled="true"
                  :editable="editable"
                  label="최초 가동일"
                  name="operationStartDate"
                  v-model="data.operationStartDate"
                  @datachange="getHolidaies"
                />
              </div>
              <!-- <div class="col-2">
                <c-checkbox
                  :editable="editable"
                  :isFlag="true"
                  label="휴무일 여부"
                  name="holidayFlag"
                  v-model="data.holidayFlag"
                  @datachange="getHolidaies"
                />
              </div> -->
              <div class="col-4">
                <c-text
                  :disabled="true"
                  :editable="editable"
                  type="number"
                  suffix="시간"
                  label="하루 가동시간(기본 가동시간)"
                  name="operateTime"
                  v-model="data.operateTime"
                  @dataChange="getHolidaies"
                />
              </div>
              <div class="col-4">
                <c-text
                  :disabled="true"
                  :editable="editable"
                  type="number"
                  suffix="시간"
                  label="경과시간"
                  name="lapseTime"
                  v-model="data.lapseTime"
                />
              </div>
              <div class="col-4">
                <c-text
                  :disabled="true"
                  :editable="editable"
                  type="number"
                  suffix="시간"
                  label="총 가동시간"
                  name="operationSumTime"
                  v-model="data.operationSumTime"
                />
              </div>
              <div class="col-4">
                <c-text
                  :disabled="true"
                  :editable="editable"
                  type="number"
                  suffix="%"
                  label="가동률"
                  name="operationPercent"
                  v-model="data.operationPercent"
                />
              </div>
            </template>
          </c-card>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
          <c-table
            ref="table"
            title="설비 비가동 목록"
            :columns="grid.columns"
            :data="data.operationList"
            :gridHeight="grid.height"
            :editable="false"
            :isFullScreen="false"
            :columnSetting="false"
            :isTitle="true"
            :filtering="false"
            :hideBottom="true"
            :isExcelDown="false"
            :gridHeightAuto="true"
            :usePaging="false"
            @table-data-change="tableDataChange"
          >
          </c-table>
        </div>
      </div>
    </q-form>
  </div>
</template>

<script>
import { uid } from 'quasar';
import selectConfig from '@/js/selectConfig';
import transactionConfig from '@/js/transactionConfig';
export default {
  name: 'equipment-operation-time',
  props: {
    param: {
      type: Object,
      default: () => ({
        equipmentCd: '',
        equipmentNo: '',
        plantName: '',
        plantCd: '',
        processCds: [],
        equipmentTypeName: '',
        equipmentName: '',
      }),
    },
    editable: {
      type: Boolean,
      default: true,
    },
    revEquipmentCd: {
      type: String,
      default: '',
    }
  },
  data() {
    return {
      data: {
        plantCd: null,
        equipmentCd: '',  
        operatioStartDate: '', 
        holidayFlag: 'N', 
        operateTime: '', 
        operationList: [],
        lapseTime: '',
        operationSumTime: '',
        operationPercent: '',
      },
      grid: {
        columns: [
          {
            name: 'breakNo',
            field: 'breakNo',
            label: '발생번호',
            align: 'right',
            style: 'width:100px',
            sortable: false,
          },
          {
            name: 'breakName',
            field: 'breakName',
            label: '가동정지명(정지사유)',
            align: 'left',
            style: 'width:200px',
            sortable: false,
          },
          {
            name: 'breakTypeCd',
            field: 'breakTypeCd',
            label: '설비상태',
            align: 'center',
            style: 'width:300px',
            type: 'select',
            codeGroupCd: 'WO_EQUIP_STOP_CD',
            sortable: false,
          },
          {
            name: 'breakDtm',
            field: 'breakDtm',
            label: '가동정지일시',
            dateType: 'datetime',
            align: 'left',
            minuteStep: 30,
            range: true,
            style: 'width:200px',
            sortable: false,
          },
          {
            name: 'breakTime',
            field: 'breakTime',
            label: '가동정지시간(h)',
            align: 'center',
            style: 'width:100px',
            sortable: false,
          },
          {
            name: 'remark',
            field: 'remark',
            label: '비고',
            align: 'left',
            style: 'width:200px',
            sortable: false,
          },
        ],
        data: [],
        height: '800px',
      },
      listUrl: '',
      updateUrl: transactionConfig.mdm.equipment.operation.update.url,
      isSave: false,
      mappingType: 'PUT',
      holidayUrl: '',
      holidayList: [],
      sumBreakTime: 0,
    }
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
  },
  mounted() {
    this.$_.cloneDeep(this.param)
    this.init();
  },
  beforeDestroy() {
  },
  computed: {
  },
  watch: {
    'revEquipmentCd'() {
      this.getDetail();
      this.getHolidaies();
    }
  },
  methods: {
    init() {
      // this.editable = this.$route.meta.editable;
      this.holidayUrl = selectConfig.sys.holiday.list.url;
      this.listUrl = selectConfig.mdm.equipment.operation.list.url;
      this.updateUrl = transactionConfig.mdm.equipment.operation.update.url,
      
      this.getDetail();
      this.getHolidaies();
    },
    getHolidaies() {
      if (this.data.operationStartDate) {
        this.$http.url = this.holidayUrl;
        this.$http.type = 'GET';
        this.$http.param = {
          holidayFlag: 'C'
        }
        this.$http.request((_result) => {
          this.holidayList = this.$_.map(_result.data, 'holidayDt');
          this.dataChange();
        },);
      }
    },
    getDetail() {
      this.sumBreakTime = 0;
      if (this.param.equipmentCd) {
        this.$http.url = this.listUrl;
        this.$http.type = 'GET';
        this.$http.param = {
          equipmentCd: this.param.equipmentCd,
          userId: this.$store.getters.user.userId,
        };
        this.$http.request((_result) => {
          this.data = _result.data
          if (this.data.operationList && this.data.operationList.length > 0) {
            this.$_.forEach(this.data.operationList, _item => {
              _item.breakDtm = [_item.breakStartDt + ' ' + _item.breakStartTime, 
                              _item.breakEndDt + ' ' + _item.breakEndTime ]
            })
          }
        })
      }
    },
    saveInfo() {
      this.$refs['editForm'].validate().then(_result => {
        if (_result) {
          window.getApp.$emit('CONFIRM', {
            title: '확인',
            message: '저장하시겠습니까?',
            // TODO : 필요시 추가하세요.
            type: 'info', // success / info / warning / error
            // 확인 callback 함수
            confirmCallback: () => {
              this.data.regUserId = this.$store.getters.user.userId;
              this.data.chgUserId = this.$store.getters.user.userId;
              this.data.equipmentCd = this.param.equipmentCd;
              
              if (this.data.operationList && this.data.operationList.length > 0) {
                this.$_.forEach(this.data.operationList, _item => {
                  _item.breakStartDt = _item.breakDtm[0].split(' ')[0];
                  _item.breakStartTime = _item.breakDtm[0].split(' ')[1];
                  _item.breakEndDt = _item.breakDtm[1].split(' ')[0];
                  _item.breakEndTime = _item.breakDtm[1].split(' ')[1];
                })
              }
              this.isSave = !this.isSave;
            },
            // 취소 callback 함수
            cancelCallback: () => {
            },
          });
        } else {
          window.getApp.$emit('APP_VALID_ERROR');
        }
      });
    },
    saveCallback() {
      window.getApp.$emit('APP_REQUEST_SUCCESS');
      this.getDetail();
    },
    tableDataChange(props) {
      if (props.row.breakDtm) {
        let start = this.$comm.moment(props.row.breakDtm[0])
        let end = this.$comm.moment(props.row.breakDtm[1])
        let diffMinutes = (this.$comm.moment.duration(end.diff(start)).asMinutes())
        let diffSeconds = parseInt(diffMinutes % 60);
        this.$set(props.row, 'breakTime', parseInt(diffMinutes / 60) + (diffSeconds == 30 ? '.5' : ''))
      }
    },
    dataChange() {
      this.sumBreakTime = 0;
      var count1 = 0;
      let start = this.$comm.moment(this.data.operationStartDate)
      let end = this.$comm.moment(this.$comm.getToday())
      count1 = this.$comm.getDatediff(start, end) + 1;
      // let breakDate = [];
      this.$_.forEach(this.data.operationList, _item => {
        this.sumBreakTime += Number(_item.breakTime)
        // breakDate.push(_item.breakDtm[0].split(' ')[0])
        // breakDate.push(_item.breakDtm[1].split(' ')[0])
        // console.log(this.$comm.moment(_item.breakDtm[0].split(' ')[0]))
        // console.log(this.$comm.moment(_item.breakDtm[1].split(' ')[0]))
      })
      if (this.data.operationStartDate && this.data.operateTime) {
        if (this.data.holidayFlag === 'N') {

          this.data.lapseTime = 24 * count1
          this.data.operationSumTime = (Number(this.data.operateTime) * count1) - this.sumBreakTime
          this.data.operationPercent = (((Number(this.data.operateTime) * count1) - this.sumBreakTime) / (24 * count1)) * 100
        } else {
          let start = new Date(this.data.operationStartDate); 
          let end = new Date(this.$comm.getToday());
          let count2 = 1;
          var temp_date = start;
          while (temp_date.getTime() <= end.getTime()) {  
            var tmp = temp_date.getDay();
            let month = temp_date.getMonth() + 1;
            if (Number(temp_date.getMonth() + 1) < 10) {
              month = '0' + Number(temp_date.getMonth() + 1);
            }
            let day = temp_date.getDate();
            if (Number(temp_date.getDate()) < 10) {
              day = '0' + temp_date.getDate();
            }
            let date = temp_date.getFullYear() +'-' + month  + '-' + day
            
            if(tmp == 0 || tmp == 6) {
                // 주말
            } else if (this.holidayList.indexOf(date) >= 0)  {
                // 공휴일
            } else {
                // 평일
                count2++;         
            }
            temp_date.setDate(start.getDate() + 1); 
          }
          
          this.data.lapseTime = 24 * count1 
          this.data.operationSumTime = (Number(this.data.operateTime) * count2) - this.sumBreakTime
          this.data.operationPercent = (((Number(this.data.operateTime) * count2) - this.sumBreakTime) / (24 * count1)) * 100
        }
      }
    },
    addRow() {
      this.data.operationList.push({
        equipmentOperationId: uid(),
        equipmentCd: this.param.equipmentCd,
        breakName: '',
        breakStartDt: '',
        breakEndDt: '',
        breakNo: '',
        breakStartTime: '',
        breakEndTime: '',
        breakTime: '',
        breakTypeCd: null,
        remark: '',
        regUserId: '',
        editFlag: 'C',
      })
    },
    removeRow() {
      let selectData = this.$refs['table'].selected;
      if (!selectData || selectData.length === 0) {
        window.getApp.$emit('ALERT', {
          title: '안내', // 안내
          message: '하나 이상 선택하세요.', // 하나 이상 선택하세요.
          type: 'warning', // success / info / warning / error
        });
      } else {
        if(!this.data.deleteOperationList) this.data.deleteOperationList = []
        this.$_.forEach(selectData, item => {
          if (this.$_.findIndex(this.data.deleteOperationList, { equipmentOperationId: item.equipmentOperationId }) === -1
            && item.editFlag !== 'C') {
            this.data.deleteOperationList.push(item)
          }
          this.data.operationList = this.$_.reject(this.data.operationList, item);
        })
        this.$refs['table'].$refs['compo-table'].clearSelection();
      }
    },
  }
};
</script>